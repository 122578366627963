<script setup lang="ts">
import {
	computed,
	ref,
	onMounted,
	onBeforeUnmount,
} from 'vue';
import {
	getStoreProducts,
	getVariantsQuantity,
} from '@zyro-inc/site-modules/api/StoreApi';
import { useSiteGlobal } from '@zyro-inc/site-modules/use/useSiteGlobal';
import { getStoreId } from '@zyro-inc/site-modules/utils/getters/getStoreId';
import { isAppPrerendering } from '@zyro-inc/site-modules/utils/prerenderingFlags';
import BlockEcommerceProductList from '@zyro-inc/site-modules/components/blocks/ecommerce/BlockEcommerceProductList.vue';
import { useBlockEcommerceProductList } from '@zyro-inc/site-modules/components/blocks/ecommerce/useBlockEcommerceProductList';
import { SYSTEM_LOCALE } from '@zyro-inc/site-modules/constants';
import { useEcommerceModal } from '@zyro-inc/site-modules/components/ecommerce/modals/useEcommerceModal';
import { useEcommerce } from '@zyro-inc/site-modules/components/ecommerce/useEcommerce';
import { PRODUCT_TYPE_BOOKING } from '@zyro-inc/site-modules/constants/ecommerce';
import { useEcommerceGlobal } from '@zyro-inc/site-modules/use/useEcommerceGlobal';
import {
	SiteBlock,
	SiteBlocks,
	SitePage,
} from '@hostinger/builder-schema-validator';
import { EcommerceProduct } from '@zyro-inc/site-modules/types';
import { getWebsiteCategoryUrl } from '@zyro-inc/site-modules/utils/ecommerce/category';

interface Props {
	blockId: string;
	data: SiteBlock;
	lcp: {
		type: string;
		id: string;
	};
	ecommerceTranslations: Record<string, string>;
	currentLocale: string;
	blocks: SiteBlocks;
	isCartVisible: boolean;
	isInPreviewMode: boolean;
	isMobileView: boolean;
}

const props = withDefaults(defineProps<Props>(), {
	currentLocale: SYSTEM_LOCALE,
	blocks: () => ({}),
	isCartVisible: false,
	isInPreviewMode: false,
	isMobileView: false,
});

const {
	meta,
	siteId,
} = useSiteGlobal();
const {
	isLoading: isEcommerceLoading,
	productPages,
	isShoppingCartOpen,
	shoppingCartItems,
	categories,
	fetchCategories,
	setCategories,
	setIsLoading,
	setIsLoaded,
	setShoppingCartOpen,
	setShoppingCartItems,
	setIsCheckoutLoading,
	setSelectedBookingId,
	setStoreProducts,
	setVariantsQuantity,
} = useEcommerceGlobal({
	blockId: props.blockId,
});
const {
	openEcommerceModal,
	closeEcommerceModal,
	setProductPreviewData,
} = useEcommerceModal();
const { initiateCheckout } = useEcommerce(props);

const {
	blockStyle,
	textColorVars,
	columnCount,
	productsPerPage,
	productCategoryId,
	isButtonEnabled,
	buttonDisplay,
	buttonText,
	buttonStyle,
	buttonType,
	buttonBorderWidth,
	ribbonStyle,
	imageRatio,
	productSorting,
	backgroundColor,
	isCategoryListEnabled,
	imageHoverEffect,
} = useBlockEcommerceProductList(props);

const pageCount = ref(0);
const currentPage = ref(1);
const products = ref([]);
const currentCategoryId = ref(productCategoryId.value);
const variantsQuantity = ref([]);
const sorting = ref(props.data.productSorting?.enabled
	? props.data.productSorting?.sortingOptions?.find(({ isEnabled }) => isEnabled)?.value || ''
	: '');

const productIds = computed(() => props.blocks[props.blockId]?.productIds);

const fetchProducts = async ({
	sort,
	page,
	categoryId = currentCategoryId.value,
}: {
	sort: string;
	page: number;
	categoryId?: string;
}) => {
	const storeId = getStoreId(meta.value);

	if (!storeId) {
		return;
	}

	// !IMPORTANT. Explanation is in useEcommerceGlobal.ts fetchProducts function
	setIsLoaded(false);
	setIsLoading(true);

	try {
		const productDataPromises = await Promise.allSettled([
			fetchCategories(storeId, isCategoryListEnabled.value),
			getStoreProducts(storeId, {
				bodyProductIds: productIds.value,
				offset: (page - 1) * productsPerPage.value,
				limit: productsPerPage.value,
				collectionId: categoryId,
				sort: (!sort && categoryId) ? 'order=ASC&sort_by=collection_order' : sort,
			}),
		]);

		const productDataValues = productDataPromises.map((data) => (data.status === 'fulfilled' ? data.value : {}));
		const [categoriesResponse, productsResponse] = productDataValues;
		const fetchedProductIds = productsResponse.products.map(({ id }) => id);
		const quantity = await getVariantsQuantity(storeId, fetchedProductIds);

		if (categoriesResponse.collections?.length) {
			setCategories(categoriesResponse.collections.sort((a, b) => a.title.localeCompare(b.title)));
		}

		products.value = productsResponse.products;
		variantsQuantity.value = quantity;
		pageCount.value = Math.ceil(productsResponse.count / productsResponse.limit);

		// We need to store products globally too,
		// as there are modals and probably other parts
		// that get information about products from global store
		setStoreProducts(productsResponse.products);
		setVariantsQuantity(quantity);
	} catch (error) {
		console.error(error);
	} finally {
		setIsLoading(false);
		setIsLoaded(true);
	}
};

const isClientLoaded = ref(false); // needed for astro sites to not flash loader
const blockStorePageQuery = computed(() => `store-page-${props.blockId}`);

const isProductListShown = computed(() => !!products.value?.length);
const isLoading = computed(() => isAppPrerendering || isEcommerceLoading.value || !isClientLoaded.value);

const handlePageChange = (page: number) => {
	currentPage.value = page;

	fetchProducts({
		page,
		sort: sorting.value,
		categoryId: currentCategoryId.value,
	});
};

const handleSortChange = (event: Event) => {
	const sortType = (event.target as HTMLInputElement).value;

	sorting.value = sortType;

	fetchProducts({
		sort: sortType,
		page: currentPage.value,
		categoryId: currentCategoryId.value,
	});
};

const handleCategoryChange = (id: string, page?: number) => {
	currentPage.value = 1;
	currentCategoryId.value = id;

	if (!props.isInPreviewMode) {
		const href = getWebsiteCategoryUrl(id, props.blockId);

		window.history.pushState({}, '', href);
	}

	fetchProducts({
		sort: sorting.value,
		page: page || currentPage.value,
		categoryId: currentCategoryId.value,
	});
};

const handleCategoryClick = (id: string) => {
	if (currentCategoryId.value === id) {
		return;
	}

	handleCategoryChange(id);
};

const handleButtonClick = async (product: EcommerceProduct) => {
	if (props.isInPreviewMode) {
		openEcommerceModal('EcommerceMessageButtonDisabled');

		return;
	}

	if (product.options.length) {
		const itemProductPage: SitePage | undefined = Object.values(productPages.value)
			.find((page: SitePage) => page.productId === product.id);
		const ecommerceBlocks = Object.keys(props.blocks).filter((key) => props.blocks[key].type === 'BlockEcommerceProduct');
		const pageProductBlockId = ecommerceBlocks.find((block) => itemProductPage?.blocks?.includes(block));

		if (!pageProductBlockId) {
			if (!itemProductPage) {
				window.location.assign('/');

				return;
			}

			window.location.assign(`/${itemProductPage.slug}`);

			return;
		}

		setProductPreviewData(props.blocks[pageProductBlockId]);
		openEcommerceModal('EcommerceProductPreview');

		return;
	}

	const productForShoppingCart = [
		{
			...product,
			variants: [product.variants[0]],
		},
	];

	await closeEcommerceModal();

	if (product.type.value === PRODUCT_TYPE_BOOKING) {
		setSelectedBookingId(product.id);
		openEcommerceModal('EcommerceBookingEventSelect');

		return;
	}

	if (props.isCartVisible) {
		setShoppingCartItems([
			...shoppingCartItems.value,
			...productForShoppingCart,
		]);

		if (isShoppingCartOpen.value) {
			return;
		}

		setShoppingCartOpen(true);
	} else {
		setIsCheckoutLoading(true);
		await initiateCheckout(productForShoppingCart).then(() => {
			setIsCheckoutLoading(false);
		});
	}
};

const handleBrowserNavigationPageChange = () => {
	const params = new URLSearchParams(window.location.search);
	const pageParam = params.get(blockStorePageQuery.value) || '1';
	const pageFromParams = Number.parseInt(pageParam, 10);

	if (pageFromParams === currentPage.value) {
		return;
	}

	currentPage.value = pageFromParams;
};

const handleBrowserNavigationCategoryChange = () => {
	const params = new URLSearchParams(window.location.search);
	const categoryParam = params.get('category');

	if (categoryParam === currentCategoryId.value) {
		return;
	}

	currentCategoryId.value = categoryParam;

	fetchProducts({
		sort: sorting.value,
		page: currentPage.value,
		categoryId: categoryParam || '',
	});
};

const handleWindowPopState = () => {
	handleBrowserNavigationPageChange();
	handleBrowserNavigationCategoryChange();
};

onMounted(() => {
	isClientLoaded.value = true;

	const params = new URLSearchParams(window.location.search);
	const pageParam = params.get(blockStorePageQuery.value) || '1';
	const pageFromParams = Number.parseInt(pageParam, 10);
	const categoryParam = params.get('category');
	const isPageDifferent = pageFromParams !== currentPage.value;

	if (isPageDifferent && !categoryParam) {
		currentPage.value = pageFromParams;

		handlePageChange(pageFromParams);
	} else if (categoryParam) {
		const page = isPageDifferent ? pageFromParams : currentPage.value;

		handleCategoryChange(categoryParam, page);
	} else {
		fetchProducts({
			sort: sorting.value,
			page: currentPage.value,
			categoryId: currentCategoryId.value,
		});
	}

	window.addEventListener('popstate', () => {
		handleWindowPopState();
	});
});

onBeforeUnmount(() => {
	window.removeEventListener('popstate', handleWindowPopState);
});
</script>

<template>
	<BlockEcommerceProductList
		:block-id="blockId"
		:block-style="blockStyle"
		:text-color-vars="textColorVars"
		:is-product-list-shown="isProductListShown"
		:products-per-page="productsPerPage"
		:column-count="columnCount"
		:page-count="pageCount"
		:current-page="currentPage"
		:product-pages="productPages"
		:product-category-id="currentCategoryId"
		:is-button-enabled="isButtonEnabled"
		:button-display="buttonDisplay"
		:button-text="buttonText"
		:button-style="buttonStyle"
		:button-type="buttonType"
		:button-border-width="buttonBorderWidth"
		:is-loading="isLoading"
		:ribbon-style="ribbonStyle"
		:products="products"
		:translations="ecommerceTranslations"
		:image-ratio="imageRatio"
		:image-hover-effect="imageHoverEffect"
		:is-eager="lcp.type === 'block-ecommerce-product-list' && lcp.id === blockId"
		:site-id="siteId"
		:variants-quantity="variantsQuantity"
		:product-sorting="productSorting"
		:sorting="sorting"
		:background-color="backgroundColor"
		:is-category-list-enabled="isCategoryListEnabled"
		:categories="categories"
		:is-category-item-link-disabled="isInPreviewMode"
		:is-mobile-view="isMobileView"
		@page-changed="handlePageChange"
		@sort-changed="handleSortChange"
		@button-click="handleButtonClick"
		@category-click="handleCategoryClick"
	/>
</template>
